import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import AccountSettings from './content/pages/Settings';
import ProtectedRoute, { SessionMaintained } from './components/ProtectedRoute';
import { Page } from './common/routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Authentication = Loader(
  lazy(() => import('src/content/authentication/login2'))
);
const Register = Loader(
  lazy(() => import('src/content/authentication/register'))
);

const AWSauth = Loader(
  lazy(() => import('src/content/authentication/Awsauth'))
);

const AccountCreated = Loader(
  lazy(() => import('src/content/authentication/Accountcreated'))
);

const Forgetemail = Loader(
  lazy(
    () => import('src/content/authentication/forgetpassword/forgetcodeemail')
  )
);

const Forgetauth = Loader(
  lazy(() => import('src/content/authentication/forgetpassword/forgetcode'))
);

// Dashboards
const Projects = Loader(lazy(() => import('src/content/dashboards/Projects'))); 
const Projectsettings = Loader(
  lazy(() => import('src/content/dashboards/Project-settings'))
);
const Currency = Loader(lazy(() => import('src/content/dashboards/Rates'))); 
const Currencysettings = Loader(
  lazy(() => import('src/content/dashboards/Rates-settings'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: (
      <SessionMaintained>
        <BaseLayout />
      </SessionMaintained>
    ),
    children: [
      {
        path: '/',
        element: <Authentication />
      },
      {
        path: Page.Login,
        element: <Authentication />
      },
      {
        path: 'register',
        children: [
          {
            path: '/',
            element: <Register />
          }
        ]
      },
      ,
      {
        path: 'Awsauth',
        children: [
          {
            path: '/',
            element: <AWSauth />
          }
        ]
      },
      {
        path: 'AccountCreated',
        children: [
          {
            path: '/',
            element: <AccountCreated />
          }
        ]
      },
      {
        path: 'forget',
        children: [
          {
            path: 'password',
            element: <Forgetemail />
          },
          {
            path: 'authcode',
            element: <Forgetauth />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '*',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      // {
      //   path: 'project-settings',
      //   children: [
      //     {
      //       path: '/:projectid',
      //       element: <Projectsettings />
      //     }
      //   ]
      // }
    ]
  },
  {
    path: Page.Dashboard,
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to={Page.Projects} replace />
      },
      {
        path: Page.Projects,
        element: <Projects />
      },
      {
        path: Page.ProjectSettings + '/:projectid',
        element: <Projectsettings />
      }
      ,
      {
        path: Page.Currency,
        element: <Currency />
      },
      {
        path: Page.RatesSettings + '/:rateid',
        element: <Currencysettings />
      }
      
    ]
  }
];

export default routes;
