import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
  reload: true,
  modaltype: null,
  modalmessage: null,
  sessionerror: null,
  loggedin: false,
  charts1: true,
  charts2: true,
  extractdoc: null,
  exportdoc: true,
  caseName: '',
});

export { useGlobalState, setGlobalState };
