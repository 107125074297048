import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import React, { useState } from 'react';
import { Keywordcontext } from './context/keywordcontext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setGlobalState, useGlobalState } from './state/globalstate';
import IdleTimer from './session/idle';
import SessionTimeout from './session/timeout';

declare global {
  interface Window { analytics: any; }
}

const App = () => {

  const [keyword, setKeyword] = useState<string>('');
  const value = { keyword, setKeyword };
  const content = useRoutes(routes);

  const [modalmessage] = useGlobalState('modalmessage');

  if (modalmessage) {
    modalmessage;
    setGlobalState('modalmessage', null);
  }

  const [loggedin] = useGlobalState('loggedin');

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {/* <SessionTimeout /> */}
        <IdleTimer/>
        <Keywordcontext.Provider value={value}>
          {content}
        </Keywordcontext.Provider>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
