import { useRef, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  createMuiTheme,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { eDiscoveryUrl } from 'src/common/routes';
import { setGlobalState } from 'src/state/globalstate';
import styles from '../header.module.scss'


const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

const user = {
  email: localStorage.getItem('email'),
  avatar: '/static/images/avatars/3.jpg',
  jobtitle: 'admin'
};

function HeaderUserbox() {
  let navigate = useNavigate();

  const Logoutuser = () => {
    localStorage.clear();
    navigate('/login');
    setGlobalState('loggedin', false);
  };

  const email = localStorage.getItem('email');
  const name = email.split('@');

  const user = {
    email: email,
    name: name[0],
    avatar: '/static/images/avatars/7.png',
    jobtitle: 'admin'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
   
  
  return (
    <>
      <UserBoxButton 
      // color="secondary" 
      // ref={ref} 
      // onClick={handleOpen}
      >
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />

          <UserBoxText>
            <UserBoxLabel variant="body1" className={styles.headeremail}>
              {user.name}
            </UserBoxLabel>
            <UserBoxDescription variant="body2" className="position">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>

        
      </UserBoxButton>

    </>
  );
}

export default HeaderUserbox;
