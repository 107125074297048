const Separator = '/';

//Page name only
export const Page = {
  Login: 'login',
  Dashboard: 'dashboards',
  Projects: 'projects',
  Currency: 'rates',
  ProjectSettings: 'project-settings',
  RatesSettings: 'rates-settings',
  // Awsauth: 'Awsauth'
};

//Full url of each Page
export const eDiscoveryUrl = {
  Login: Separator + Page.Login,
  Dashboard: Separator + Page.Dashboard,
  Projects: Separator + [Page.Dashboard, Page.Projects].join(Separator),
  ProjectSettings: Separator + [Page.Dashboard, Page.ProjectSettings].join(Separator),
  Currency: Separator + [Page.Dashboard, Page.Currency].join(Separator),
  RatesSettings: Separator + [Page.Dashboard, Page.RatesSettings].join(Separator),

  // Awsauth: Separator + Page.Awsauth
};
